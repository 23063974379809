import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/HeroVideoPages";
import BaseLayout from "../components/global/BaseLayout";
import ValuesOne from "../components/values/Values_1";
import BlockPrincipal from "../components/block/Block_2";
import BlockSecondary from "../components/block/Block_8";

function About() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="About">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="About"
          Subheader="About"
          urlVideo={'https://firebasestorage.googleapis.com/v0/b/videos-de-stock.appspot.com/o/Painting%2FPainting.mp4?alt=media&token=4025391a-269d-44b5-af94-9e48e341f15b'}
        />
        <BlockPrincipal
            title={'about us'}
            text={rpdata?.dbAbout?.[0]?.text}
            image={rpdata?.gallery?.[6]}
            listsAbout
          />
        <ValuesOne image={rpdata?.gallery?.[3]} />

        <BlockSecondary
          title={rpdata?.dbSlogan?.[2]?.slogan}
          text={rpdata?.dbAbout?.[1]?.text}
          image1={rpdata?.gallery?.[8]}
          image2={rpdata?.gallery?.[4]}
          image3={rpdata?.gallery?.[9]}
        />
      </div>
    </BaseLayout>
  );
}

export default About;
